import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { OrderStatDetailComponent, OrderStatsComponent, OrderViewComponent } from './order';
import { ProcessComponent } from './process';
import { InvoiceListComponent } from './invoice/invoice-list/invoice-list.component';
import { CheckStoreGuard } from './service/check-store.guard';
import { HcStoreCheckerComponent } from './components/hc-store-checker/hc-store-checker.component';
import { OrderListComponent } from './pages/order-list/order-list.component';
import { StorageListComponent } from '@confectionary-app/storage/storage-list/storage-list.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [CheckStoreGuard],
    children: [
      {
        path: '',
        redirectTo: 'order-stats',
        pathMatch: 'full'
      },
      {
        path: 'order-stats',
        component: OrderStatsComponent
      },
      {
        path: 'order-list',
        component: OrderListComponent
      },
      {
        path: 'order-stats/detail',
        component: OrderStatDetailComponent,
        pathMatch: 'full'
      },
      {
        path: 'orders/:id',
        component: OrderViewComponent,
        pathMatch: 'full'
      },
      {
        path: 'process',
        component: ProcessComponent
      },
      {
        path: 'history',
        loadChildren: () => import('./work-history/work-history.module').then(m => m.WorkHistoryModule)
      },
      {
        path: 'invoices',
        component: InvoiceListComponent
      },
      {
        path: 'storage',
        component: StorageListComponent
      }
    ],
  },
  {
    path: 'verify',
    component: HcStoreCheckerComponent,
  },
  {
    path: '**',
    redirectTo: ''
  }
];
