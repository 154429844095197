import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable, Injector, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { OrderStatDetailComponent, OrderStatsComponent, OrderViewComponent } from '@confectionary-app/order';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ProcessComponent, UserSelectComponent, WorkflowSelectComponent } from './process';
import { StepSelectComponent } from './process/step-select/step-select.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceListComponent } from './invoice/invoice-list/invoice-list.component';
import { HcStoreCheckerComponent } from './components/hc-store-checker/hc-store-checker.component';
import { ConverterPipe } from './utils/converter.pipe';
import { InvoiceEventsComponent } from './components/invoice-events/invoice-events.component';
import { OrderListComponent } from './pages/order-list/order-list.component';
import { HcIconComponent } from './components/hc-icon/hc-icon.component';
import { HcTagComponent } from './components/hc-tag/hc-tag.component';
import { HcOrderIconComponent } from './components/hc-order-icon/hc-order-icon.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { HxComponentModule } from 'hx-component';
import { InvoiceModalComponent } from '@confectionary-app/modal/invoice/invoice.modal';
import { StorageListComponent } from './storage/storage-list/storage-list.component';

import {
  ApiModule,
  ComponentType,
  Configuration,
  flattenObject,
  HX_APP_VERSION,
  HX_COMPONENT_NAME,
  HX_ENV_MODE,
  HX_KEYCLOAK,
  HxAuthService,
  HxError,
  HxPipeModule,
  initKeycloak,
  TranslocoRootModule,
  UiError
} from 'hx-services';
import { environment } from '@confectionary-env/environment';
import { APP_VERSION } from '@confectionary-env/version';
import * as Sentry from '@sentry/browser';
import { MarkdownModule } from 'ngx-markdown';
import { StorageHttpInterceptor } from '@confectionary-app/service/http.interceptor';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslocoService } from '@ngneat/transloco';

if (environment.sentryUrl) {
  Sentry.init({
    release: environment.version,
    environment: environment.production ? 'production' : 'staging',
    ignoreErrors: ['Unauthorized', 'productBalance.zero'],
    dsn: environment.sentryUrl,
    sampleRate: 0,
    autoSessionTracking: false,
    tracePropagationTargets: [],
    beforeSend: (event, hint) => {
      console.log('[sentry] before send', event, hint);
      if (hint && hint.originalException && hint.originalException instanceof HttpErrorResponse) {
        if (hint.originalException.status === 401) {
          console.log('[sentry] skipped error logging', event);
          return null;
        }
      }
      return event;
    },
    integrations: [
      new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false,
      }),
    ],
  });
}

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error: any) {
    if (error.promise && error.rejection) {
      console.error('[got-rejection] %O', error);
      error = error.rejection;
    }
    const toastr = this.injector.get(ToastrService);
    const tr = this.injector.get(TranslocoService);
    if (error instanceof HttpErrorResponse) {
      const ex: HxError = error.error?.message ? error.error as HxError : {
        code: 577,
        id: '-',
        message: error.message,
        data: error.error ?? {},
      };
      toastr.error(ex.description ?? tr.translate(ex.message, flattenObject(ex.data)), tr.translate('error') + ' ' + ex.id, { onActivateTick: true });
    }
    if (error instanceof UiError) {
      toastr.error(tr.translate(error.message, error.data ?? {}), null, { onActivateTick: true });
    } else {
      console.error('[error-handler] %O', error);
    }
    Sentry.captureException(error.originalError || error);
  }
}

function getKeycloakUrl() {
  if (environment.production) {
    return location.origin.replace(location.origin.split('//')[1].split('.')[0], 'auth') + '/auth';
  }
  return environment.keycloakUrl;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    OrderStatsComponent,
    OrderStatDetailComponent,
    OrderViewComponent,
    SidebarComponent,
    ProcessComponent,
    UserSelectComponent,
    WorkflowSelectComponent,
    StepSelectComponent,
    InvoiceListComponent,
    HcStoreCheckerComponent,
    ConverterPipe,
    InvoiceEventsComponent,
    OrderListComponent,
    HcIconComponent,
    HcTagComponent,
    HcOrderIconComponent,
    EventListComponent,
    InvoiceModalComponent,
    StorageListComponent,
  ],
  imports: [
    ToastrModule.forRoot(),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES, {preloadingStrategy: PreloadAllModules}),
    TranslocoRootModule,
    KeycloakAngularModule,
    NgbModule,
    HxPipeModule,
    HxComponentModule,
    MarkdownModule.forRoot(),
    FullCalendarModule,
    ApiModule.forRoot(() => new Configuration({basePath: ''})),
  ],
  exports: [HcIconComponent],
  providers: [
    { provide: HX_COMPONENT_NAME, useValue: ComponentType.cf },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StorageHttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    {
      provide: HX_KEYCLOAK,
      useValue: {
        url: getKeycloakUrl(),
        clientId: environment.keycloakClientId,
        realm: environment.keycloakRealm
      }
    },
    {
      provide: HX_APP_VERSION,
      useValue: APP_VERSION
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService, HxAuthService, HX_KEYCLOAK, TranslocoService],
    },
    { provide: HX_ENV_MODE, useValue: environment.production },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
