import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { endOfMonth, format, parseISO, startOfDay, startOfMonth } from 'date-fns';
import {
  DateRange,
  extractDateRange,
  HxAuthService,
  HxOrderService,
  HxStoreService,
  isoDate,
  RemainderProductModel,
  StoreBasicModel,
  toRouterQueryParams,
  uiLabel
} from 'hx-services';
import { CalendarOptions, EventApi, ViewApi } from '@fullcalendar/core';
import { TranslocoService } from '@ngneat/transloco';
import { FullCalendarComponent } from '@fullcalendar/angular';

/**
 * Component Stats of product's balance and reserved
 */

interface RetailOutletModel extends StoreBasicModel {
  selected?: boolean;
}

@Component({
  selector: 'app-order-stats',
  templateUrl: './order-stats.component.html',
  styleUrls: ['./order-stats.component.css']
})
export class OrderStatsComponent implements OnInit, OnDestroy {
  @ViewChild('templateRef') public templateRef: TemplateRef<any>;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarOptions?: CalendarOptions;
  list: RemainderProductModel[] = [];
  isLoading = {
    calendarEvents: false,
    list: false,
  };
  params: { date: DateRange, storeIds: number[] } = {
    date: { from: isoDate(startOfMonth(Date.now())), to: isoDate(endOfMonth(Date.now())) },
    storeIds: []
  };
  selectedDate: Date;
  categoryTitle: string;
  retailOutlets: RetailOutletModel[] = [];
  private sub?: Subscription;

  constructor(
    private orderService: HxOrderService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private modal: NgbModal,
    private storeService: HxStoreService,
    private auth: HxAuthService,
    private tr: TranslocoService,
  ) {
  }

  async ngOnInit() {
    console.log('[order-stats] ngOnInit  ', this.auth.getUiLang());
    const [daygrid, interaction, listGrid, retailOutlets] = await Promise.all([
      import('@fullcalendar/daygrid'),
      import('@fullcalendar/interaction'),
      import('@fullcalendar/list'),
      this.storeService.getRetailOutlets(this.auth.user.store.id),
    ]);

    this.calendarOptions = {
      plugins: [
        daygrid.default,
        interaction.default,
        listGrid.default,
      ],
      editable: false,
      selectable: true,
      // eventLimit: false,
      height: 'auto',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,listMonth'
      },
      firstDay: 1,
      // views: {
      //   week: {
      //     columnHeaderFormat: 'ddd D.M'
      //   }
      // },
      eventClick: this.onEventClick.bind(this),
      select: this.onDateClick.bind(this),
      datesSet: this.onDatesSet.bind(this),
      events: [],
      buttonText: {
        today: this.tr.translate('full-calendar.today'),
        month: this.tr.translate('full-calendar.month'),
        week: this.tr.translate('full-calendar.week'),
        list: this.tr.translate('full-calendar.list'),
      },
      locale: this.auth.getUiLang(),
      allDayText: this.tr.translate('full-calendar.allDayText'),
    };

    this.params.storeIds = [this.auth.user.store.id];
    this.retailOutlets = [];
    if (retailOutlets.length > 0) {
      this.retailOutlets = [this.auth.user.store, ...retailOutlets];
      this.retailOutlets.forEach(r => {
        r.selected = true;
        if (!this.params.storeIds.includes(r.id)) {
          this.params.storeIds.push(r.id);
        }
      });
      await this.navigateUrl();
    }

    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      const asArrFn = (name: string, mapFn: (key: string) => any) => paramMap.get(name) ? paramMap.get(name).split(',').map(mapFn) : this.params.storeIds;
      this.params.date = extractDateRange('date', paramMap) ?? {from: isoDate(startOfMonth(Date.now())), to: isoDate(endOfMonth(Date.now()))};
      this.params.storeIds = asArrFn('storeIds', id => Number(id));
      this.loadList();
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  isoDate(date?: number) {
    return date ? isoDate(date) : undefined;
  }

  getCategoryTitle() {
    let date;
    if (this.selectedDate) {
      date = `за ${format(this.selectedDate, 'dd.MM')}`;
    }
    return this.categoryTitle ? `по категории «${this.categoryTitle}» ${date}` : `${date}`;
  }

  onDateClick(event: { start?: Date }) {
    this.categoryTitle = '';
    if (!this.isPast(event.start)) {
      this.selectedDate = event.start;
      this.showModal();
      this.loadProducts();
    }
  }

  onEventClick(ev: { event: EventApi }) {
    // console.log('onEventClick', ev);
    if (!this.isPast(ev.event.start)) {
      this.categoryTitle = ev.event.title || '';
      this.selectedDate = ev.event.start;
      this.showModal();
      this.loadProducts(ev.event.extendedProps.categoryId);
    } else {
      this.categoryTitle = '';
    }
  }

  async onDatesSet(event: { view: ViewApi }) {
    const [year, month, date] = this.params.date.from.split('-');
    const [newYear, newMonth, newDate] = isoDate(event.view.currentStart).split('-');
    this.params.date.from = isoDate(event.view.currentStart);
    this.params.date.to = isoDate(endOfMonth(event.view.currentStart));
    if (year !== newYear || month !== newMonth) {
      await this.navigateUrl();
    }
  }

  isToday(): boolean {
    const useDate = this.selectedDate || new Date();
    const date = new Date(+useDate);
    const today = new Date();

    return date.toDateString() === today.toDateString();
  }

  toggleRetailOutlet(type: RetailOutletModel) {
    type.selected = !type.selected;
    if (this.params.storeIds.includes(type.id)) {
      this.params.storeIds.splice(this.params.storeIds.indexOf(type.id), 1);
    } else {
      this.params.storeIds.push(type.id);
    }
    this.navigateUrl();
  }

  onDateChanged() {
    this.navigateUrl();
  }

  private getSelectedStoreIds(): number[] {
    return this.retailOutlets.filter(ro => ro.selected).map(ro => ro.id);
  }

  private isPast(day: Date): boolean {
    const today = startOfDay(Date.now());
    return day.getTime() < today.getTime();
  }

  private async loadList() {
    // keep colors in 3 column format :) intellijIdea shows color on left side!
    const colors = [
      '#467fcf', '#6574cd', '#a55eea',
      '#f66d9b', '#cd201f', '#fd9644',
      '#f1c40f', '#5eba00', '#2bcbba',
      '#17a2b8', '#868e96', '#343a40',
      '#45aaf2', '#7bd235', '#467fcf',
      '#868e96', '#5eba00', '#45aaf2',
      '#f1c40f', '#cd201f', '#343a40'
    ];
    this.isLoading.calendarEvents = true;
    if (this.retailOutlets.length > 0 && this.params.storeIds.length === 0) {
      this.isLoading.calendarEvents = false;
    } else {
      try {
        const categoryProducts = await this.orderService.getProductCountsByCategoryAndDate({ fromDate: this.params.date.from, toDate: this.params.date.to, storeIds: this.params.storeIds});
        let calendarApi = this.calendarComponent.getApi();
        calendarApi.removeAllEvents();
        if (categoryProducts && categoryProducts.length) {
          const calendarEvents = categoryProducts.map(item => {
            const isPastDay = this.isPast(parseISO(item.date)) ? 'is--past-day' : 'is--cursor-pointer';
            return {
              start: new Date(item.date),
              date: item.date,
              backgroundColor: colors[item.categoryId - 1],
              className: `${isPastDay} cf-fc-event`,
              title: `${item.productCount} - ${uiLabel(this.tr.getActiveLang(), item.categoryTitle)}`,
              description: uiLabel(this.tr.getActiveLang(), item.categoryTitle),
              categoryId: item.categoryId,
              allDay: true,
            };
          });
          calendarApi.addEventSource(calendarEvents);
        }
      } catch (err: any) {

      } finally {
        this.isLoading.calendarEvents = false;
      }
    }
  }

  private async navigateUrl() {
    await this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }

  private loadProducts(category?: string) {
    this.list = [];
    this.isLoading.list = true;
    const categoryId = category ? Number(category) : undefined;
    this.orderService.getRemainders({date: isoDate(this.selectedDate), categoryIds: [categoryId], storeIds: this.params.storeIds}).then(result => {
      this.list = result;
      this.isLoading.list = false;
    }, () => {
      this.isLoading.list = false;
    });
  }

  private showModal() {
    const userModal = this.modal.open(this.templateRef, {size: 'lg'});
    userModal.result.then(() => this.clearModalData()).catch(() => this.clearModalData());
  }

  private clearModalData() {
    this.list = [];
    this.selectedDate = undefined;
  }
}
