import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HxAuthService, HxCategoryService, HxStoreProductService, ProductCategoryModel, SortType, StoreProductModel } from 'hx-services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-storage-list',
  templateUrl: './storage-list.component.html',
  styleUrls: ['./storage-list.component.css']
})
export class StorageListComponent implements OnInit, OnDestroy {
  list: StoreProductModel[] = [];
  isLoading = {
    list: false,
  };
  pagination = {
    currentPage: 1,
    allItemCount: 0,
    limit: 10,
    maxPage: 1
  };
  query: string;
  orderBy = 'amount'; // from big to small
  sort: SortType = SortType.desc;
  categoryId = 9;
  categories: ProductCategoryModel[] = [];
  private sub: Subscription;

  constructor(
    private categoryService: HxCategoryService,
    private storeProductService: HxStoreProductService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private auth: HxAuthService,
  ) { }

  ngOnInit() {
    this.loadCategories();
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.pagination.currentPage = paramMap.get('page') ? Number(paramMap.get('page')) : 1;
      this.orderBy = paramMap.get('orderBy');
      this.sort = paramMap.get('sort') as any || SortType.desc;
      this.query = paramMap.get('query');
      this.categoryId = paramMap.get('categoryId') ? Number(paramMap.get('categoryId')) : this.categoryId;
      this.loadList();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  pageChanged(event = this.pagination.currentPage) {
    this.isLoading.list = true;
    this.list = [];

    let count = 1;
    const page = Math.ceil(this.pagination.allItemCount / this.pagination.limit);

    if (event > (page + 1)) {
      count = this.pagination.currentPage - 1;
    } else {
      count = (event <= this.pagination.maxPage) ? event : this.pagination.maxPage;
    }

    this.navigate(count);
  }

  updateFilter() {
    this.navigate();
  }

  resetFilter() {
    this.query = undefined;
    this.pagination.currentPage = 1;
    this.categoryId = undefined;
    this.navigate();
  }

  sortTable() {
    this.sort = this.sort === SortType.asc ? SortType.desc : SortType.asc;
    this.pagination.currentPage = 1;
    this.navigate();
  }

  changeCategory(categoryId: number) {
    this.categoryId = categoryId;
    this.navigate();
  }

  private loadList() {
    this.isLoading.list = true;
    this.list = [];
    this.storeProductService.getStoreProductList({
      page: this.pagination.currentPage,
      orderBy: this.orderBy,
      sort: this.sort,
      query: this.query,
      categoryId: this.categoryId,
      storeId: this.auth.user.store?.id
    }).subscribe(result => {
      this.isLoading.list = false;
      this.list = result.list || [];
      this.pagination.allItemCount = result.count;
    }, () => this.isLoading.list = false);
  }

  private loadCategories() {
    const brandId = this.auth.user.store.brandId;
    this.categoryService.getCategories({limit: 1000, brandId: brandId}).subscribe(result => {
      this.categories = result.list;
    });
  }

  private navigate(page?: number) {
    this.router.navigate(['./'], {
      queryParams: {
        page: page,
        query: this.query,
        orderBy: this.orderBy,
        sort: this.sort,
        categoryId: this.categoryId,
      },
      relativeTo: this.aRoute
    });
  }
}
